import TerminalPic from "../assets/terminal.jpeg"
import CRMPic from "../assets/crm.png"
import MoviePic from "../assets/movie.jpeg"
import GiftPic from "../assets/gift.png"
import WeatherPic from "../assets/weather.jpeg"

export const projectList = [
    {
        name: "Real Estate CRM",
        image: CRMPic,
        link: "none",
        desc: "An advanced CRM built for Real Estate Licensees. Built using React.js, .NET, and MySQL. No link available yet"
    },
    {
        name: "Simple Shell in C",
        image: TerminalPic,
        link: "https://github.com/bentzlec/simple_shell_c",
        desc: "This program is a simple shell implementation in C, which allows the user to enter and execute commands. It supports background processes, foreground-only mode, input/output redirection, and a few built-in commands such as cd, exit, and status. The shell also includes signal handling to manage Ctrl+C and Ctrl+Z keyboard inputs, which respectively ignore the interrupt signal and toggle foreground-only mode. Additionally, it provides a feature to replace $$ in user input with the shell's process ID. The main loop of the program prompts the user for input, tokenizes the input into arguments, and calls appropriate functions based on the command. The shell also handles cleaning up background processes and freeing memory when necessary."
    },
    {
        name: "CSV Move Info File Processor in C",
        image: MoviePic,
        link: "https://github.com/bentzlec/movie_csv",
        desc: "This program is a C program designed to process CSV files containing movie information, specifically titles and years. The program allows the user to choose a file to process by selecting the largest file, the smallest file, or specifying the file name. Once a file is selected, it creates a directory with a random name, reads the CSV file, and generates a set of text files inside the newly created directory."
    },
    {
        name: "eBay API Gift Recommender",
        image: GiftPic,
        link: "https://github.com/osu-cs290-sp20/GrabAGift_WebDevFinal.git",
        desc: "Recommends a gift retrieved via the eBay API after the completion of a survey. Group project for Web Development."
    },
    {
        name: "Android Weather App",
        image: WeatherPic,
        link: "",
        desc: "A weather app build in Android Studio. Uses the Room Persistence Library, a SQLite database, and the Jetpack Navigation library. No link available yet."
    }
]