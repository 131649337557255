// Home.js
import React from 'react';
import '../styles/Home.css';
import Logo from "../assets/cb_logo_large.png"

const Home = () => {
  return (
    <div className="home">
      <section className="intro">
        <img className="logo" src={Logo} />
        <h2 className="name">Connor Bentzley</h2>
        <h3>Data Engineer, Business Analyst, & Software Developer</h3>
        <p>Currently seeking positions in Data Engineering and Software Development</p>
      </section>
      <section className="skills">
        <h3>Skills and Technologies</h3>
        <div className="skill-container">
          <div className="skills-column">
            <h4>Languages</h4>
            <ul>
              <li>Python</li>
              <li>C++</li>
              <li>C</li>
              <li>JavaScript</li>
              <li>MySQL</li>
              <li>R</li>
            </ul>
          </div>
          <div className="skills-column">
            <h4>Packages/Technologies</h4>
            <ul>
              <li>TensorFlow</li>
              <li>Scikit-learn</li>
              <li>Pandas</li>
              <li>NumPy</li>
              <li>Node.js</li>
              <li>KNIME / Alteryx</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
