import React from 'react';
import "../styles/Footer.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import Email from '@material-ui/icons/Email';

function Footer() {
  return (
    <div className='footer'>
        <div className='socialMedia'>
            <a href='mailto:bentzley@icloud.com' >
                <Email/>
            </a>
            <a href='https://www.linkedin.com/in/connor-bentzley/'>
                <LinkedInIcon/>
            </a>
            <a href='https://github.com/bentzlec' >
                <GitHubIcon/>
            </a>
        </div>
        <p> &copy; 2023 Connor Bentzley</p>
    </div>
  );
}

export default Footer