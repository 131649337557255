import React from "react";
import { useParams } from "react-router-dom";
import { projectList } from "../helper/ProjectList";
import GitHubIcon from "@material-ui/icons/GitHub";
import "../styles/ProjectDetail.css";

function ProjectDetail() {
  const { id } = useParams();
  const project = projectList[id];
  return (
    <div className="project">
      <h1> {project.name}</h1>
      <img src={project.image} />
      <p>
        <b>Description: </b> {project.desc}
      </p>
      <a href = {project.link} >
        <GitHubIcon className="icon"/>
      </a>
    </div>
  );
}

export default ProjectDetail;